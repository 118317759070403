<template>
    <div>
        <div class="modal fade" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-bold" id="exampleModalLabel">{{ contentData.title }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                       {{ contentData.bodyText }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Entendi</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
 props: {
    contentData: {
        type: Object,
        required: true
    }
 }
}
</script>